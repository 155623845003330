<div class="absolute top-[10px] right-0">
  <app-rounded-icon-button
    icon="person"
    link="profile/edit"
  ></app-rounded-icon-button>
  <app-rounded-icon-button
    *ngIf="!isAuthenticated"
    icon="login"
    link="login"
  ></app-rounded-icon-button>
  <app-rounded-icon-button
    *ngIf="isAuthenticated"
    icon="logout"
    (click)="logout()"
  ></app-rounded-icon-button>
</div>
