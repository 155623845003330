<div class="app-container">
  <div class="flex-grow">
    <div class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="w-full max-w-md">
        <div>
          <img class="mx-auto h-24 w-auto mb-20" src="../../../../assets/img/SBS__LOGO__ELearning.png" alt="SBS Logo" />
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Passwort vergessen
          </h2>
        </div>

        <!-- Success/Error Message -->
        <div *ngIf="success || error" class="mb-5">
          <div *ngIf="error" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 relative"
            role="alert">
            <p>{{ error }}</p>
            <mat-icon class="absolute top-2 right-2 cursor-pointer" (click)="closeModal()">close</mat-icon>
          </div>
          <div *ngIf="success" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 relative"
            role="alert">
            <p>{{ success }}</p>
            <mat-icon class="absolute top-2 right-2 cursor-pointer" (click)="closeModal()">close</mat-icon>
          </div>
        </div>

        <!-- Forgot Password Form -->
        <form class="mt-8 space-y-6" (ngSubmit)="forgotPassword()">
          <div class="rounded-md shadow-sm">
            <!-- E-Mail Input -->
            <div>
              <label for="email-address" class="sr-only">{{ 'e-learning_training_login_mail' | translate }}</label>
              <input [formControl]="emailFormControl" id="email-address" name="email" type="email"
                placeholder="{{ 'e-learning_training_login_mail' | translate }}" class="relative block w-full appearance-none rounded-none rounded-t-md border
                focus:outline-none
                border-{{
                  emailFormControl.invalid ? 'red' : 'gray'
                }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{
                  emailFormControl.invalid ? 'red' : 'indigo'
                }}-500
                focus:ring-{{
                  emailFormControl.invalid ? 'red' : 'indigo'
                }}-500 sm:text-sm" required />
              <mat-error *ngIf="emailFormControl.invalid">
                {{ getErrorMessage() }}
              </mat-error>
            </div>
          </div>

          <!-- Submit Button -->
          <div>
            <button
              class="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-300 disabled:cursor-not-allowed"
              [disabled]="emailFormControl.invalid" type="submit">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <mat-icon> lock </mat-icon>
              </span>
              Passwort zuücksetzen
            </button>
          </div>
        </form>

        <!-- Back to Login Link -->
        <div class="mt-3">
          <a routerLink="/login" class="font-medium text-red-700 hover:text-red-500">
            Zurück zum Login
          </a>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>