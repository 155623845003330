import {AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-blank-space',
  templateUrl: './blank-space.component.html',
  styleUrls: ['./blank-space.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankSpaceComponent implements AfterViewInit{

  @Input() options!: any[];
  @Input() useFillOptions: boolean = false;
  @Input() isQuizCompleted: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tries: number = 0;
  @Input() correct: boolean = false;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter<any>();

  finishedInit = false;
  optionIds: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    // this.sliceIndexes = this.options.map((option) => option?.question?.indexOf(`%A%`));

    this.options.forEach((option) => {
      if (!option.fillTextOrg) {
        option.fillTextOrg = JSON.parse(JSON.stringify(option.fillText));
      }
      const entries = Object.entries(option.fillText);
      entries.forEach(([first, second]: any[]) => {
        option.fillText[first] = option.fillTextOrg[first];
        console.log(option.selected);
        console.log('fillTextOrg[first]', option.fillTextOrg[first]);
        option.fillTextOrg[first].split("{{RP}}").forEach((field: string, index: number) => {
          console.log(index);
          const value = option.selected[first] ? option.selected[first][index] : '';
          console.log('option.selected[first][index]', option.selected[first]);
          let disabled = "";
          if (this.disabled) {
            disabled = "disabled";
          }
          if (this.useFillOptions) {
            let replace = '<select name="' + option.id + '_' + first + '_' + index + '" style="border-bottom: #e20716 solid 2px; color: black" ' + disabled + '>';
            replace += '<option value=""></option>';
            option.answerOptions[first].forEach((answerOption: string) => {
              if (answerOption === value) {
                replace += '<option value="' + answerOption + '" selected>' + answerOption + '</option>';
              } else {
                replace += '<option value="' + answerOption + '">' + answerOption + '</option>';
              }
            });
            replace += '</select>';
            option.fillText[first] = option.fillText[first].replace("{{RP}}", replace);
          } else {
            const replace =
              '<input name="' + option.id + '_' + first + '_' + index + '" value="' + value + '" style="border-bottom: #e20716 solid 2px; color: black" ' + disabled + ' />';
            option.fillText[first] = option.fillText[first].replace("{{RP}}", replace);
          }
        });
        console.log(option.fillText[first]);
      });
      this.optionIds.push(option.id);
    });
    this.finishedInit = true;
  }

  ngAfterViewInit() {
    this.optionIds.forEach((id) => {
      const name = id + '_de';
      let input = null;
      if(this.useFillOptions) {
        input = document.querySelectorAll('select[name^="' + name + '"]');
      } else {
        input = document.querySelectorAll('input[name^="' + name + '"]');
      }

      if (input.length > 0) {
        input.forEach((input, index) => {
          const ids = input.getAttribute('name')?.split('_')!;
          const option = this.options.filter((option) => option.id === ids[0])[0];
          input.addEventListener("change", (evt) => {
            this.updateValue(evt, index, input.getAttribute('name') || '');
          });
          if (option.selected[ids[1]]) {
            const value = option.selected[ids[1]][ids[2]] || '';
            input.setAttribute('value', value);
          }
        });
      }
    });
  }

  updateValue(e: any, index: number, name: string) {
    console.log(e);
    console.log(name + ' input changed for index ' + index, e.target.value);
    const id = name.split('_');
    const option = this.options.filter((option) => option.id === id[0])[0];
    if (option.selected[id[1]]) {
      option.selected[id[1]][index] = e.target.value
    } else {
      option.selected[id[1]] = {};
      option.selected[id[1]][index] = e.target.value
    }
    option.selected[id[1]]['text'] = option.fillTextOrg[id[1]];
    option.fillTextOrg[id[1]].split('{{RP}}').forEach((item: string, index: number) => {
      if (option.selected[id[1]][index]) {
        option.selected[id[1]]['text'] = option.selected[id[1]]['text'].replace('{{RP}}', option.selected[id[1]][index]);
      } else {
        option.selected[id[1]][index] = '';
        option.selected[id[1]]['text'] = option.selected[id[1]]['text'].replace('{{RP}}', '');
      }
    });
    console.log(this.options);
    this.selectedOption.emit(this.options);
  }
}
