import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  updateUser(uid: string, user: any) {
    return this.firestore.collection('users').doc(uid).update(user);
  }
}
