import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Quiz} from "../models/quiz.model";
import {environment} from "../../../../environments/environment";
import {AuthenticationService} from "../../../core/services/auth/authentication.service";
import {QuizResult} from "../models/quiz-result.model";

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  env = environment;
  trainingUrl = this.env.functionsUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  getQuestionJson() {
    return this.http.get<any>('../../../../assets/questions/questions.json');
  }

  getQuizzes(trainingId: string, chapterId: string, contentId: string) : Promise<Quiz[]> {
    return new Promise((resolve) => {
      this.authService.getToken().subscribe((token) => {
        this.http.get<Quiz[]>(`${this.trainingUrl}/getAllQuizzes?trainingId=${trainingId}&chapterId=${chapterId}&contentId=${contentId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).subscribe((quizzes) => {
          resolve(quizzes);
        });
      });
    });
  }

  getQuizResult(trainingId: string, chapterId: string, contentId: string, result: any[]) : Promise<QuizResult> {
    return new Promise((resolve) => {
      this.authService.getToken().subscribe((token) => {
        this.http.post<QuizResult>(`${this.trainingUrl}/getQuizResults?trainingId=${trainingId}&chapterId=${chapterId}&contentId=${contentId}`,
          result, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).subscribe((result) => {
          resolve(result);
        });
      });
    });
  }


}
