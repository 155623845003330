import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html',
  styleUrls: ['./course-progress.component.scss']
})
export class CourseProgressComponent implements OnInit {

  @Input() chapters: any[] = [];
  @Input() trainingCompleted: boolean = false;

    preparedChapters: any[] = [];

  constructor() {

  }

  ngOnInit(): void {
    const preparedChapters = this.chapters.sort((a, b) => (a.order > b.order) ? 1 : -1);
    // add the next chapter to the current chapter
    preparedChapters.forEach((chapter, index) => {
      if (index + 1 < this.chapters.length) {
        chapter.nextChapter = this.chapters[index + 1];
      }
    });
    this.preparedChapters = preparedChapters;
  }

}
