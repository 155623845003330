import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Content} from "../../models/content.model";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent {

  @Input() trainingId: string = '';
  @Input() chapterId: string = '';
  @Input() content!: Content;
  @Input() userVideo: any;

  @Output() quizCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() timeTrackingChanged: EventEmitter<boolean> = new EventEmitter<any>();

  onQuizCompleted(completed: boolean) {
    this.quizCompleted.emit(completed);
  }

  onTimeTrackingChanged(changed: any) {
    this.timeTrackingChanged.emit(changed);
  }
}
