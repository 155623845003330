import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { FooterComponent } from './components/footer/footer.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { RegistrationComponent } from "./pages/registration/registration.component";

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    RegistrationComponent,
    EmailVerificationComponent,
    FooterComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatButtonModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    RegistrationComponent,
    LoginComponent,
    EmailVerificationComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    }
  ],
})
export class LoginModule { }
