import { Injectable } from '@angular/core';
import {LanguageService} from "./translate/language.service";
import {UserTrainingService} from "../../shared/services/user-training.service";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private languageService: LanguageService
  ) { }

  init() {
    return new Promise<void>((resolve) => {
      console.log("AppInitService.init() called");
      this.languageService.getAllTranslation().then(() => {
        console.log("AppInitService.init() finished");
        resolve();
      });
    });
  }
}
