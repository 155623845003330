import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-icon-button',
  templateUrl: './rounded-icon-button.component.html',
  styleUrls: ['./rounded-icon-button.component.scss']
})
export class RoundedIconButtonComponent {
  @Input() icon: string = 'cancel';
  @Input() link: string = 'home';
  @Input() color: string = 'danger';

}
