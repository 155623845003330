export class Content {
  id?: string;
  title: any;
  type: string;
  imageType: string;
  intro: any;
  description: any;
  image?: any;
  video?: any;
  quiz?: string;
  content?: any;
  order: number;
  time: number;
  updated?: Date;
  created?: Date;

  constructor(id: string, title: any, type: string, imageType: string, intro: any, description: any, image: any, video: any, quiz: any, content: any, order: number, time: number, created?: Date, updated?: Date) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.imageType = imageType;
    this.intro = intro;
    this.description = description;
    this.image = image;
    this.video = video;
    this.quiz = quiz;
    this.content = content;
    this.order = order;
    this.time = time;
    this.created = created;
    this.updated = updated;
  }
}

export const contentConverter = {
  toFirestore: (content: Content) => {
    return {
      title: content.title,
      type: content.type,
      imageType: content.imageType,
      intro: content.intro,
      description: content.description,
      image: content.image,
      video: content.video,
      quiz: content.quiz,
      content: content.content,
      order: content.order,
      time: content.time,
      created: content.created || new Date(),
      updated: content.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Content(snapshot.id, data.title, data.type, data.imageType, data.intro, data.description, data.image, data.video, data.quiz, data.content, data.order, data.time, data.created.toDate(), data.updated.toDate());
  }
};
