import {Component, OnInit} from '@angular/core';
import {TrainingService} from "../../services/training.service";
import {Training} from "../../models/training.model";
import {UserTrainingService} from "../../../../shared/services/user-training.service";
import {UserTraining} from "../../../../shared/models/user-training.model";

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent implements OnInit {

  trainings: Training[] = [];

  constructor(
    private trainingService: TrainingService,
    private userTrainingService: UserTrainingService
  ) { }

  ngOnInit(): void {
    this.getTrainings();
  }

  getTrainings() {
    this.userTrainingService.getUserTrainings().then((userTrainings: UserTraining[]) => {
      this.trainingService.getTrainings().then(trainingsSnap => {
        const trainings: Training[] = [];
        trainingsSnap.docs.map(t => {
          const training = t.data();
          if (userTrainings.find(ut => ut.id === t.id)) {
            training.started = true;
          }
          trainings.push(t.data());
        });
        this.trainings = trainings;
      });
    });
  }

}
