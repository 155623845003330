import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login/pages/login.component';
import { AuthenticationService } from './core/services/auth/authentication.service';
import { AuthGuard } from './core/services/auth/auth.guard';
import { ProfilePageComponent } from './features/profile/pages/profile-page/profile-page.component';
import { ProfileEditComponent } from './features/profile/pages/profile-edit/profile-edit.component';

import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './core/login/pages/forgot-password/forgot-password.component';
import { QuizzesComponent } from './features/quizzes/pages/quizzes.component';
import { TrainingsComponent } from './features/trainings/pages/trainings/trainings.component';
import {TrainingDetailsComponent} from "./features/trainings/pages/training-details/training-details.component";
import {TrainingViewComponent} from "./features/trainings/pages/training-view/training-view.component";
import {RegistrationComponent} from "./core/login/pages/registration/registration.component";
import { EmailVerificationComponent } from './core/login/pages/email-verification/email-verification.component';
import { EmailVerifiedAuthGuard } from './core/services/auth/email-verfied-auth.guard';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Dashboard' },
    component: ProfilePageComponent
  },
  {
    path: 'login',
    data: { title: 'Login' },
    component: LoginComponent
  },
  {
    path: 'email-verfication',
    data: { title: 'Email verficication' },
    component: EmailVerificationComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'registration',
    data: { title: 'Registrierung' },
    component: RegistrationComponent
  },
  {
    path: 'forgot-password',
    data: { title: 'Forgot Password' },
    component: ForgotPasswordComponent,
  },
  {
    path: 'profile',
    data: { title: 'Profile' },
    component: ProfilePageComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'profile/edit',
    data: { title: 'Profile' },
    component: ProfileEditComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'trainings',
    data: { title: 'Trainings' },
    component: TrainingsComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'trainings/:id',
    data: { title: 'Trainings' },
    component: TrainingDetailsComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'trainings/:id/chapter/:chapterId/content',
    data: { title: 'Chapter' },
    component: TrainingViewComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'trainings/:id/chapter/:chapterId/content/:contentOrderNo',
    data: { title: 'Chapter' },
    component: TrainingViewComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: 'trainings/:id/chapter/:chapterId/quiz',
    data: { title: 'Quiz' },
    component: QuizzesComponent,
    canActivate: [EmailVerifiedAuthGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: [AuthenticationService, AuthGuard,EmailVerifiedAuthGuard],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
