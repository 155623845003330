import { Component } from '@angular/core';
import { AuthenticationService } from './core/services/auth/authentication.service';
import { getAuth } from 'firebase/auth';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SBS-Online-Training';

  currentUserAuthSub: any;
  currentUserDataSub: any;

  currentUserData: any;

  uid: string = '';

  loading: boolean = true;

  auth = getAuth()
  pageTitle: string = '';

  

  constructor(
    public auth_service: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit() {
    this.currentUserAuthSub = this.auth.onAuthStateChanged(user => {
      if (user && user.uid) {
        this.loading = true;
        this.uid = user.uid;
        if (user.emailVerified) {
           this.auth_service.showOutlet = true;
          this.getCurrentUserData();
          this.router.navigate(['/trainings']);
        } else {
           this.auth_service.showOutlet = false;
          this.router.navigate(['/email-verfication']);
        }
      } else {
        this.loading = true;
        this.currentUserDataSub = null;
         this.auth_service.showOutlet = false;
        this.router.navigate(['/login']);
      }
  
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute)
        rt.data.subscribe((data: any) => {
          this.pageTitle = data.title;
        })
      })
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getCurrentUserData() {
    this.currentUserDataSub = this.auth_service.getUserData(this.uid).subscribe(userData => {
      this.currentUserData = userData;
    });
  }

  ngOnDestroy() {
    if (this.currentUserDataSub) {
      this.currentUserDataSub.unsubscribe();
    }
    this.currentUserAuthSub();
  }
}
