import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

  constructor(
    private authService: AuthenticationService,
    private translate: TranslateService
  ) { }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  success: string | undefined;
  error: string | undefined;

  getErrorMessage(): string {
    if (this.emailFormControl.hasError('required')) {
      return this.translate.instant('e-learning_training_required_error');
    }

    return this.emailFormControl.hasError('email') ? this.translate.instant('e-learning_training_invalid_mail_error') : '';
  }

  closeModal() {
    this.success = undefined;
    this.error = undefined;
  }

  forgotPassword() {
    if (this.emailFormControl.valid) {
      this.authService
        .forgotPassword(this.emailFormControl.value as string)
        .then(() => {
          this.success = 'E-Mail zum Zurücksetzen des Passworts gesendet, überprüfen Sie Ihren Posteingang.';
        })
        .catch((error) => {
          switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-not-found': {
              this.error = 'E-Mail nicht gefunden!';
              break;
            }
            default: {
              this.error = 'Unerwarteter Fehler';
              break;
            }
          }
        });
    }
  }
}
