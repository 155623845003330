import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dnd-sort',
  templateUrl: './dnd-sort.component.html',
  styleUrls: ['./dnd-sort.component.scss'],
})
export class DndSortComponent {
  @Input() left!: any[];
  @Input() right!: any[];
  @Input() isQuizCompleted: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tries: number = 0;
  @Input() correct: boolean = false;
  @Output() selectionResult: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.right,
      event.previousIndex,
      event.currentIndex
    );

    this.changeHandler();
  }

  dragEnded(event: any) {
    console.log('event', event);
    console.log('event.target', event.target);
  }

  changeHandler() {
    this.left.forEach((l, index) => {
      l.selected = this.right[index];
    })
    console.log(this.left);
    this.selectionResult.emit(this.left);
  }

}
