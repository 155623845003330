import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})

export class SidenavComponent {
  sidenavLinks: any = [
    {
      name: 'Dashboard',
      link: '/',
      icon: 'home',
      exact: true
    },
    {
      name: 'Trainings',
      link: '/trainings',
      icon: 'book',
      exact: false
    }
  ];
  @Input() userInfo: any;
  @Input() pageTitle: any;

  events: string[] = [];
  opened: boolean = true;
  currentPath: string = '';
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    public auth_service: AuthenticationService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
