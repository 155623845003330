export class Simple {
  id?: string;
  name: string;
  nameLabel?: string;
  name2?: string;
  name2Label?: string;
  updated?: Date;
  created?: Date;

  constructor(id: string, name: string, nameLabel?: string, name2?: string, name2Label?: string, created?: Date, updated?: Date) {
    this.id = id;
    this.name = name;
    this.nameLabel = nameLabel;
    this.name2 = name2;
    this.name2Label = name2Label;
    this.created = created;
    this.updated = updated;
  }
}

export const simpleConverter = {
  toFirestore: (simple: Simple) => {
    return {
      name: simple.name
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Simple(snapshot.id, data.name, '', '', '', data.created.toDate(), data.updated.toDate());
  }
};
