import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-multi-choice',
  templateUrl: './multi-choice.component.html',
  styleUrls: ['./multi-choice.component.scss'],
})
export class MultiChoiceComponent {
  @Input() options: any;
  @Input() isQuizCompleted: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tries: number = 0;
  @Input() correct: boolean = false;
  @Output() selectOptions: EventEmitter<any> = new EventEmitter<any>();

  selectedOptions: any[] = [];

  ngOnInit(): void {
    this.selectedOptions = this.options.filter((option: any) => option.selected);
  }

  changeHandler(event: any[]) {
    this.options.forEach((option: any) => {
      option.selected = event.some((e: any) => e.id === option.id);
    });
    this.selectOptions.emit(this.options);
  }
}
