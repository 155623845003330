import { Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where
} from "@angular/fire/firestore";
import {UserTraining, userTrainingConverter} from "../models/user-training.model";
import {AuthenticationService} from "../../core/services/auth/authentication.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {switchMap} from "rxjs";
import {StripeService} from "ngx-stripe";

@Injectable({
  providedIn: 'root'
})
export class UserTrainingService {

  db = getFirestore();
  userTrainings: UserTraining[] = [];
  url = environment.functionsUrl;
  purchaseSuccessUrl = environment.purchaseSuccessUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private stripeService: StripeService,
  ) { }

  getUserTrainings(): Promise<UserTraining[]> {
    return new Promise((resolve) => {
      if (this.userTrainings.length === 0) {
        this.authService.getCurrentUserPromise().then((user) => {
          const q = query(collection(this.db, "userTrainings"),
            where("userId", "==", user?.uid!));
          onSnapshot(q.withConverter(userTrainingConverter),
            (querySnapshot) => {
              const userTrainings: UserTraining[] = [];
              querySnapshot.forEach((doc) => {
                userTrainings.push(doc.data());
              });
              this.userTrainings = userTrainings;
              resolve(this.userTrainings);
            });
        });
      } else {
        resolve(this.userTrainings);
      }
    });
  }

  getUserTraining(trainingId: string): Promise<UserTraining | undefined> {
    return new Promise((resolve) => {
      if (this.userTrainings.length === 0) {
        this.getUserTrainings().then(() => {
          resolve(this.userTrainings.find(ut => ut.trainingId === trainingId));
        });
      } else {
        resolve(this.userTrainings.find(ut => ut.trainingId === trainingId));
      }
    });
  }

  async startTraining(trainingId: string, trainingTitle: string, totalTime: number) {
    const user = await this.authService.getCurrentUserPromise();
    const userTraining: UserTraining = {
      trainingId: trainingId,
      trainingTitle: trainingTitle,
      totalTime: totalTime,
      totalCompletedTime: 0,
      userId: user?.uid!,
      startedAt: new Date(),
      updated: new Date(),
      chapters: [],
      completed: false,
      calculateTrainingStatus: true,
      completedPercents: 0,
      exam: false,
      examMin: 0,
      examResults: [],
      passed: false,
      reachedPoints: 0,
      totalPoints: 0,
      score: 0,
      badgeUrl: ''
    };
    return this.getUserTraining(trainingId).then((ut) => {
      if (!ut) {
        return setDoc(doc(this.db, "userTrainings", user?.uid! + '_' + trainingId), userTraining);
      }
      return;
    });
  }

  async addChapterStart(trainingId: string, chapterId: string, contentId: string) {
    const userTraining = await this.getUserTraining(trainingId);
    if (userTraining && !userTraining.completed) {
      const chapter = userTraining.chapters.find(ch => ch.chapterId === chapterId);
      if (!chapter) {
        userTraining.chapters.push({
          chapterId: chapterId,
          contents: [{
            contentId: contentId,
            completed: false,
            startedAt: new Date()
          }]
        });
        userTraining.updated = new Date();
        return updateDoc(doc(this.db,
          "userTrainings", userTraining.userId + '_' + trainingId),
          {
            chapters: userTraining.chapters,
            updated: userTraining.updated,
            calculateTrainingStatus: true
          });
      }
    }
  }

  async addCompletedContent(trainingId: string, chapterId: string, contentId: string) {
    const userTraining = await this.getUserTraining(trainingId);
    if (userTraining && !userTraining.completed) {
      const chapter = userTraining.chapters.find(ch => ch.chapterId === chapterId);
      if (chapter) {
        const content = chapter.contents.find((c: any) => c.contentId === contentId);
        if (content) {
          content.completed = true;
          content.updated = new Date();
        } else {
          chapter.contents.push({
            contentId: contentId,
            completed: true,
            updated: new Date()
          });
        }
      } else {
        userTraining.chapters.push({
          chapterId: chapterId,
          contents: [{
            contentId: contentId,
            completed: true,
            updated: new Date()
          }]
        });
      }
      userTraining.updated = new Date();
      return updateDoc(doc(this.db,
        "userTrainings", userTraining.userId + '_' + trainingId),
        {
          chapters: userTraining.chapters,
          updated: userTraining.updated,
          calculateTrainingStatus: true
        });
    }
  }

  async updateVideoStatus(trainingId: string, chapterId: string, contentId: string, videoUpdates: any) {
    const userTraining = await this.getUserTraining(trainingId);
    if (userTraining && !userTraining.completed) {
      const chapter = userTraining.chapters.find(ch => ch.chapterId === chapterId);
      if (chapter) {
        const content = chapter.contents.find((c: any) => c.contentId === contentId);
        if (content) {
          content.video = videoUpdates;
          content.updated = new Date();
        } else {
          chapter.contents.push({
            contentId: contentId,
            video: videoUpdates,
            updated: new Date()
          });
        }
      } else {
        userTraining.chapters.push({
          chapterId: chapterId,
          contents: [{
            contentId: contentId,
            video: videoUpdates,
            updated: new Date()
          }]
        });
      }
      userTraining.updated = new Date();
      return updateDoc(doc(this.db,
        "userTrainings", userTraining.userId + '_' + trainingId),
        {
          chapters: userTraining.chapters,
          updated: userTraining.updated,
          calculateTrainingStatus: true
        });
    }
  }

  async saveExamResult(trainingId: string, chapterId: string, contentId: string, result: any[]) {
    const userTraining = await this.getUserTraining(trainingId);
    if (userTraining && !userTraining.completed) {
      return updateDoc(doc(this.db,
          "userTrainings", userTraining.userId + '_' + trainingId),
        {
          examResults: result
        });
    }
  }

  hasPurchasedTraining(trainingId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.getCurrentUserPromise().then((user) => {
        const q = query(collection(this.db, "userTrainingPurchases"),
          where("userId", "==", user?.uid!),
          where("trainingId", "==", trainingId));
        onSnapshot(q, (querySnapshot) => {
          resolve(!querySnapshot.empty);
        });
      });
    });
  }

  async purchaseTraining(trainingId: string) {
    const that = this;
    this.authService.getToken().subscribe((token) => {
      const successUrl = this.purchaseSuccessUrl + '/trainings/' + trainingId;
      this.http.get<any>(`${this.url}/createPaymentSession?trainingId=${trainingId}&successUrl=${successUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).pipe(
        switchMap(session => {
          return that.stripeService.redirectToCheckout({ sessionId: session.id })
        })
      )
      .subscribe(result => {
        if (result.error) {
          alert(result.error.message);
        }
      });
    });
  }

  async deleteUserTraining(trainingId: string) {
    const user = await this.authService.getCurrentUserPromise();
    return this.getUserTraining(trainingId).then((ut) => {
      if (ut) {
        console.log('deleteDoc', user?.uid! + '_' + trainingId);
        return deleteDoc(doc(this.db, "userTrainings", user?.uid! + '_' + trainingId));
      }
      return;
    });
  }
}
