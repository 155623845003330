import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-circle',
  templateUrl: './step-circle.component.html',
  styleUrls: ['./step-circle.component.scss']
})
export class StepCircleComponent {
  @Input() step!: string | number;
  @Input() completed!: boolean;
  @Input() active!: boolean;
  @Input() icon!: string;
  @Input() point!: number;
}
