import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';

import { SidenavComponent } from './components/sidenav/sidenav.component';

import { LoginModule } from './login/login.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { RoundedIconButtonComponent } from './components/rounded-icon-button/rounded-icon-button.component';
import { NavElementsComponent } from './components/sidenav/components/nav-elements/nav-elements.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    RoundedIconButtonComponent,
    TopNavComponent,
    NavElementsComponent,
  ],
  imports: [
    CommonModule,
    LoginModule,
    MatSidenavModule,
    MatCheckboxModule,
    FormsModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
  ],
  providers: [],
  exports: [
    HeaderComponent,
    SidenavComponent,
  ]
})
export class CoreModule { }
