import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInfoComponent } from './profile/components/profile-info/profile-info.component';
import { ProfilePageComponent } from './profile/pages/profile-page/profile-page.component';
import { TrainingDetailsBoxComponent } from './profile/components/training-details-box/training-details-box.component';
import { ProfileEditComponent } from './profile/pages/profile-edit/profile-edit.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalInfoFormComponent } from './profile/components/personal-info-form/personal-info-form.component';
import { CertificateTableComponent } from './profile/components/certificate-table/certificate-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { QuizzesModule } from './quizzes/quizzes.module';
import { MatIconModule } from '@angular/material/icon';
import { ChapterModule } from './chapter/chapter.module';
import {TrainingsModule} from "./trainings/trainings.module";
import {SharedModule} from "../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    ProfileInfoComponent,
    ProfilePageComponent,
    TrainingDetailsBoxComponent,
    ProfileEditComponent,
    PersonalInfoFormComponent,
    CertificateTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    QuizzesModule,
    MatIconModule,
    ChapterModule,
    TrainingsModule,
    SharedModule,
    TranslateModule,
  ]
})
export class FeaturesModule { }
