<div *ngIf="!correct && tries == 1"  class="text-red-700 pl-8 mt-8">
  {{'e-learning_quiz_not_correct_hint' | translate}}
</div>
<mat-selection-list
  class="custom-selection-list quiz-option !m-6"
  [(ngModel)]="selectedOption"
  (ngModelChange)="changeHandler($event)"
  [multiple]="false"
>
  <mat-list-option
    color="primary"
    *ngFor="let option of options"
    [value]="option"
    [class.error]="isQuizCompleted && !option.result && tries > 1"
    [class.success]="isQuizCompleted && option.result && (tries > 1 || correct) && option.selected"
    [disabled]="disabled"
  >
    <p class="option-text">
      <span class="text-lg font-medium">{{ option.answer | ct }}</span>
    </p>
  </mat-list-option>
</mat-selection-list>
