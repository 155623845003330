import {TrainingChapter} from "./training-chapter.model";

export class Training {
  id?: string;
  title: any;
  categories: string[];
  targetGroups: string[];
  intro: any;
  description: any;
  image?: any;
  details: {
    totalTime: number;
    chapters: TrainingChapter[];
  } | null;
  updated?: Date;
  created?: Date;

  constructor(id: string, title: any, categories: string[], targetGroups: string[], intro: any, description: any, image: any, details: {
    totalTime: number;
    chapters: TrainingChapter[];
  } | null, created?: Date, updated?: Date) {
    this.id = id;
    this.title = title;
    this.categories = categories;
    this.targetGroups = targetGroups;
    this.intro = intro;
    this.description = description;
    this.image = image;
    this.details = details;
    this.created = created;
    this.updated = updated;
  }
}

export const trainingConverter = {
  toFirestore: (training: Training) => {
    return {
      title: training.title,
      categories: training.categories,
      targetGroups: training.targetGroups,
      intro: training.intro,
      description: training.description,
      image: training.image || '',
      created: training.created || new Date(),
      updated: training.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Training(snapshot.id, data.title, data.categories, data.targetGroups, data.intro, data.description, data.image, data.details, data.created.toDate(), data.updated.toDate());
  }
};
