import { Injectable } from '@angular/core';
import {collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where} from "@angular/fire/firestore";
import {simpleConverter} from "../../../shared/models/simple.model";
import {trainingConverter} from "../models/training.model";
import {contentConverter} from "../../content/models/content.model";

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  db = getFirestore();
  trainingRef = collection(this.db, "trainings");
  trainingCategoriesRef = collection(this.db, "trainingCategories");
  targetGroupsRef = collection(this.db, "trainingTargetGroups");

  constructor() { }

  categories: any[] = [];

  getTrainings() {
    const q = query(this.trainingRef.withConverter(trainingConverter),
      where("published", "==", true));
    return getDocs(q);
  }

  getTraining(id: string) {
    const ref = doc(this.db, "trainings", id);
    return getDoc(ref.withConverter(trainingConverter));
  }

  async getTrainingCategories() {
    if (this.categories.length === 0) {
      const querySnapshot = await getDocs(this.trainingCategoriesRef.withConverter(simpleConverter));
      querySnapshot.forEach((doc) => {
        this.categories.push(doc.data());
      });
    }
    return this.categories;
  }
}
