import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent {



  constructor(
    public auth_service: AuthenticationService,
    private router: Router,
  ) {

  }


  logout() {
    this.auth_service.signOut();
  }

  sendEmailVerification() {
    this.auth_service.sendEmailVerification();
  }

  checkEmailVerified() {
    this.auth_service.checkEmailVerified().then(isVerified => {
      if (isVerified) {
        this.auth_service.showOutlet = true;
         this.router.navigate(['/trainings']);
      } else {
        alert('Bitte verifizieren Sie Ihre E-Mail-Adresse, um fortzufahren.');
      }
    });
    }
}
