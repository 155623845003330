// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBCQsqT_hU_y1UfUpEIrw2QNGRyFUeikC8',
    authDomain: 'sbs-app-staging.firebaseapp.com',
    databaseURL: 'https://sbs-app-staging.firebaseio.com',
    projectId: 'sbs-app-staging',
    storageBucket: 'sbs-app-staging.appspot.com',
    messagingSenderId: '190645810172',
    storageUrl: 'https://firebasestorage.googleapis.com/v0/b/'
  },
  functionsUrl: 'https://europe-west3-sbs-app-staging.cloudfunctions.net',
  // functionsUrl: 'http://127.0.0.1:5001/sbs-app-staging/europe-west3',
  purchaseSuccessUrl: 'https://staging.sbs-schulung.de',
  // purchaseSuccessUrl: 'http://localhost:4200',
  stripePublicKey: 'pk_test_51PVxa7BuvvgAbUaiVeYYKes6Q069vV72NsgTHogTfJO1luOE9BQLCbbk0fn5kkauiW1tOHllRDEHIY7XHBkaWdcs00V7nS6oPS',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
