<div class="flex flex-col lg:flex-row">
  <div class="basis-2/5 lg:mt-8">
    <app-profile-info
      *ngIf="userInfo"
      [userInfo]="userInfo"
      [photoURL]="photoURL"
    ></app-profile-info>
  </div>
  <div class="basis-3/5 mt-16 lg:mt-0" *ngIf="userInfo">
    <div class="flex lg:flex-row flex-wrap" *ngIf="!isAdmin">
      <h2 class="text-3xl">{{'e-learning_training_dashboard_overview' | translate}}</h2>
      <div class="py-2 w-full flex flex-col gap-6">
        <div
          class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 h-full"
        >
          <app-training-details-box
            [routerLink]="'/trainings/' + userTraining.trainingId"
            *ngFor="let userTraining of userTrainings"
            [trainingName]="userTraining.trainingTitle!"
            [progressPercent]="userTraining.completedPercents"></app-training-details-box>

        </div>
        <div *ngIf="userTrainings.length == 0">
          {{'e-learning_training_dashboard_overview_none' | translate}}
        </div>
        <div class="mt-3">
          <a
            routerLink="/trainings"
            class="font-medium text-red-700 hover:text-red-500"
          >
            {{'e-learning_training_dashboard_trainings_link' | translate}}
          </a>
        </div>
      </div>
      <div class="flex flex-col mt-14" *ngIf="passedTrainingsWithBadges.length > 0">
        <h2 class="text-3xl">{{'e-learning_training_certificates' | translate}}</h2>
        <div class="py-2 w-full">
          <div *ngFor="let userTraining of passedTrainingsWithBadges">
            <img [src]="userTraining.badgeUrl" class="w-48 mt-4" />
          </div>
        </div>
      </div>
    </div>

    <div [class]="isAdmin ? 'mt-0' : 'certificate-section-container mt-16'">
      <app-certificate-table
        *ngIf="userInfo"
        [isAdmin]="isAdmin"
        [userInfo]="userInfo"
      ></app-certificate-table>
      <div *ngIf="false" class="flex items-center justify-end">
        <div class="w-4 h-4 bg-blue-700 mr-1"></div>
        <div>valid to</div>

        <div class="w-4 h-4 bg-red-700 ml-3 mr-1"></div>
        <div class="mr-3">overdue</div>

        <div class="w-4 h-4 bg-green-700 mr-1"></div>
        <div>valid forever</div>
      </div>
    </div>
  </div>
</div>
