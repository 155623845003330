import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';
import { QuizzesComponent } from './pages/quizzes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { singleChoiceComponent } from './components/answers-type/single-choice/single-choice.component';
import { MultiChoiceComponent } from './components/answers-type/multi-choice/multi-choice.component';
import { BlankSpaceComponent } from './components/answers-type/blank-space/blank-space.component';
import { DndSortComponent } from './components/answers-type/dnd-sort/dnd-sort.component';
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    QuizzesComponent,
    singleChoiceComponent,
    MultiChoiceComponent,
    BlankSpaceComponent,
    DndSortComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    MatListModule,
    MatButtonModule,
    ReactiveFormsModule,
    DragDropModule,
    SharedModule,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    QuizzesComponent
  ]
})
export class QuizzesModule { }
