import { Component } from '@angular/core';
import { getAuth } from 'firebase/auth';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  isAuthenticated: boolean = false;
  auth = getAuth();
  user: any = '';

  currentUser: any;

  constructor(
    public auth_service: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth_service.getCurrentUser().subscribe((user) => {
      if (user) {
        this.isAuthenticated = true;
        this.user = user.uid;
      } else {
        this.isAuthenticated = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.currentUser.unsubscribe();
  }

  logout() {
    this.auth_service.signOut();
  }
}
