import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-single-choice',
  templateUrl: './single-choice.component.html',
  styleUrls: ['./single-choice.component.scss']
})
export class singleChoiceComponent {
  @Input() options: any;
  @Input() isQuizCompleted: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tries: number = 0;
  @Input() correct: boolean = false;
  @Output() selectOption: EventEmitter<any> = new EventEmitter<any>();

  selectedOption: any[] = [];

  ngOnInit(): void {
    this.selectedOption = this.options.filter((option: any) => option.selected);
  }

  changeHandler(event: any) {
    this.options.forEach((option: any) => {
      option.selected = option.id === event[0].id;
    });
    this.selectOption.emit(this.options);
  }
}
