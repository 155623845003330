import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsComponent } from './pages/trainings/trainings.component';
import { TrainingListComponent } from './components/training-list/training-list.component';
import { TrainingBoxComponent } from './components/training-box/training-box.component';
import { TrainingDetailsComponent } from './pages/training-details/training-details.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../../shared/shared.module";
import {ChapterModule} from "../chapter/chapter.module";
import {ListChapterComponent} from "./components/list-chapter/list-chapter.component";
import {MatListModule} from "@angular/material/list";
import { TrainingViewComponent } from './pages/training-view/training-view.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CourseProgressComponent} from "./components/course-progress/course-progress.component";
import {StepCircleComponent} from "./components/course-progress/components/step-circle/step-circle.component";
import {StepConnectorComponent} from "./components/course-progress/components/step-connector/step-connector.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [
    TrainingsComponent,
    TrainingListComponent,
    TrainingBoxComponent,
    TrainingDetailsComponent,
    ListChapterComponent,
    TrainingViewComponent,
    CourseProgressComponent,
    StepCircleComponent,
    StepConnectorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    ChapterModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatProgressSpinnerModule
  ],
})
export class TrainingsModule { }
