import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent {
  hide = true;
  accountErrorMessage: string | undefined;

  firstNameFormControl = new FormControl('', [Validators.required, Validators.minLength(2)]);
  lastNameFormControl = new FormControl('', [Validators.required, Validators.minLength(2)]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  termsFormControl: FormControl = new FormControl(false, [Validators.requiredTrue]);

  constructor(
    private auth_service: AuthenticationService,
    private auth: AngularFireAuth,
    private router: Router,
    private translate: TranslateService
  ) { }

  getErrorMessage(control: FormControl, field: string): string {
    if (control.hasError('required')) {
      return `${field} ist erforderlich`;
    }
    if (control.hasError('minlength')) {
      const minLength = control.getError('minlength').requiredLength;
      return `${field} muss mindestens ${minLength} Zeichen lang sein`;
    }
    if (control.hasError('email')) {
      return 'Ungültige E-Mail-Adresse';
    }
    return '';
  }

  isFormInvalid() {
    return (
      this.firstNameFormControl.invalid ||
      this.lastNameFormControl.invalid ||
      this.emailFormControl.invalid ||
      this.passwordFormControl.invalid ||
      this.termsFormControl.invalid

    );
  }

  register() {
    if (!this.isFormInvalid()) {
      const email = this.emailFormControl.value!;
      const password = this.passwordFormControl.value!;
      const firstName = this.firstNameFormControl.value!;
      const lastName = this.lastNameFormControl.value!;

      this.auth_service.signUp(email, password, firstName, lastName)
        .then()
        .catch((error) => {
          console.error(error.code, error.message);
          switch (error.code) {
            case 'auth/email-already-in-use':
              this.accountErrorMessage = 'Diese E-Mail-Adresse ist bereits registriert';
              break;
            default:
              this.accountErrorMessage = 'Unerwarteter Fehler';
              break;
          }
        });
    }
  }
}