import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-details-box',
  templateUrl: './training-details-box.component.html',
  styleUrls: ['./training-details-box.component.scss']
})
export class TrainingDetailsBoxComponent {
  @Input() trainingName: string = 'Training Name';
  @Input() date!: string;
  @Input() progressPercent: number = 1;
}
