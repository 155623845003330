<div class="app-container">
  <div class="flex-grow">
    <div class="input-container flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
      <div class="w-full max-w-md">
         <div>
          <!-- <img class="mx-auto h-24 w-auto mb-20" src="../../../../assets/img/SBS__LOGO__ELearning.png" alt="SBS Logo" /> -->
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {{ 'e-learning_training_registration_title' | translate }}
          </h2>
        </div> 

        <form class="mt-8 space-y-6">
          <!-- Fehlermeldung -->
          <div *ngIf="accountErrorMessage" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
            <p class="font-bold">{{ 'e-learning_training_registration_error' | translate }}</p>
            <p>{{ accountErrorMessage }}</p>
          </div>

          <!-- Vorname -->
          <div>
            <label for="first-name" class="sr-only">Vorname</label>
            <input [formControl]="firstNameFormControl" id="first-name" name="firstName" type="text" placeholder="Vorname"
              class="relative block w-full appearance-none rounded-none rounded-t-md border focus:outline-none border-{{ firstNameFormControl.invalid ? 'red' : 'gray' }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{ firstNameFormControl.invalid ? 'red' : 'indigo' }}-500 focus:ring-{{ firstNameFormControl.invalid ? 'red' : 'indigo' }}-500 sm:text-sm" required />
            <mat-error *ngIf="firstNameFormControl.invalid">{{ getErrorMessage(firstNameFormControl, 'Vorname') }}</mat-error>
          </div>

          <!-- Nachname -->
          <div>
            <label for="last-name" class="sr-only">Nachname</label>
            <input [formControl]="lastNameFormControl" id="last-name" name="lastName" type="text" placeholder="Nachname"
              class="relative block w-full appearance-none rounded-none rounded-t-md border focus:outline-none border-{{ lastNameFormControl.invalid ? 'red' : 'gray' }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{ lastNameFormControl.invalid ? 'red' : 'indigo' }}-500 focus:ring-{{ lastNameFormControl.invalid ? 'red' : 'indigo' }}-500 sm:text-sm" required />
            <mat-error *ngIf="lastNameFormControl.invalid">{{ getErrorMessage(lastNameFormControl, 'Nachname') }}</mat-error>
          </div>

          <!-- E-Mail -->
          <div>
            <label for="email-address" class="sr-only">E-Mail</label>
            <input [formControl]="emailFormControl" id="email-address" name="email" type="email" placeholder="E-Mail"
              class="relative block w-full appearance-none rounded-none rounded-t-md border focus:outline-none border-{{ emailFormControl.invalid ? 'red' : 'gray' }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{ emailFormControl.invalid ? 'red' : 'indigo' }}-500 focus:ring-{{ emailFormControl.invalid ? 'red' : 'indigo' }}-500 sm:text-sm" required />
            <mat-error *ngIf="emailFormControl.invalid">{{ getErrorMessage(emailFormControl, 'E-Mail') }}</mat-error>
          </div>

          <!-- Passwort -->
          <div>
            <label for="password" class="sr-only">Passwort</label>
            <div class="flex items-center relative">
              <input [formControl]="passwordFormControl" id="password" name="password" [type]="hide ? 'password' : 'text'" placeholder="Passwort"
                class="relative block w-full appearance-none rounded-none rounded-t-md border focus:outline-none border-{{ passwordFormControl.invalid ? 'red' : 'gray' }}-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-{{ passwordFormControl.invalid ? 'red' : 'indigo' }}-500 focus:ring-{{ passwordFormControl.invalid ? 'red' : 'indigo' }}-500 sm:text-sm" required />
              <button type="button" (click)="hide = !hide" class="absolute inset-y-0 right-2 flex items-center">
                <mat-icon class="icon">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
            </div>
            <mat-error *ngIf="passwordFormControl.invalid">{{ getErrorMessage(passwordFormControl, 'Passwort') }}</mat-error>
          </div>

          <!-- Datenschutz und AGB Checkbox -->
          <div>
            <label class="flex items-start">
              <input type="checkbox" class="h-4 w-4 text-red-600 border-gray-300 rounded" [formControl]="termsFormControl" required>
              <span class="ml-2 text-sm text-gray-700">
                Ich akzeptiere die <a href="/terms" target="_blank" class="text-red-500 hover:underline">AGB</a> und die
                <a href="https://www.sbs-info.de/datenschutzhinweise" target="_blank" class="text-red-500 hover:underline">Datenschutzrichtlinien</a>.
              </span>
            </label>
          </div>

          <!-- Registrierungsbutton -->
          <div>
            <button
              class="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-300 disabled:cursor-not-allowed"
              [disabled]="isFormInvalid()" (click)="register()">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <mat-icon> lock </mat-icon>
              </span>
              {{ 'e-learning_training_register' | translate }}
            </button>
          </div>
        </form>

        <!-- Login-Link -->
        <div class="mt-3">
          <a routerLink="/login" class="font-medium text-red-700 hover:text-red-500">
            {{ 'e-learning_training_login_link' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>