import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedAuthGuard  {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isEmailVerified().pipe(
      map((isVerified: boolean) => {
        
        if (!isVerified) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
          });
        }
        return isVerified;
      }),
      catchError(() => {
        this.router.navigate(['/login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
