export class UserTraining {
  id?: string;
  trainingId: string;
  trainingTitle: any;
  totalTime: number;
  totalCompletedTime: number;
  userId: string;
  startedAt: Date;
  updated: Date;
  chapters: any[];
  completed: boolean;
  calculateTrainingStatus: boolean;
  completedPercents: number;
  exam: boolean;
  examMin: number;
  examResults?: any[];
  passed: boolean;
  reachedPoints: number;
  totalPoints: number;
  score: number;
  badgeUrl: string;

  constructor(id: string, trainingId: string, trainingTitle: any, totalTime: number, totalCompletedTime: number, userId: string, startedAt: Date, updated: Date, chapters: any[], completed: boolean = false, calculateTrainingStatus: boolean = true, completedPercents: number = 0, exam: boolean = false, examMin: number = 0, examResults?: any[], passed: boolean = false, reachedPoints: number = 0, totalPoints: number = 0, score: number = 0, badgeUrl: string = '') {
    this.id = id;
    this.trainingId = trainingId;
    this.trainingTitle = trainingTitle;
    this.totalTime = totalTime;
    this.totalCompletedTime = totalCompletedTime;
    this.userId = userId;
    this.startedAt = startedAt;
    this.updated = updated;
    this.chapters = chapters;
    this.completed = completed;
    this.calculateTrainingStatus = calculateTrainingStatus;
    this.completedPercents = completedPercents;
    this.exam = exam;
    this.examMin = examMin;
    this.examResults = examResults;
    this.passed = passed;
    this.reachedPoints = reachedPoints;
    this.totalPoints = totalPoints;
    this.score = score;
    this.badgeUrl = badgeUrl;
  }
}

export const userTrainingConverter = {
  toFirestore: (userTraining: UserTraining) => {
    return {
      trainingId: userTraining.trainingId,
      trainingTitle: userTraining.trainingTitle,
      totalTime: userTraining.totalTime,
      totalCompletedTime: userTraining.totalCompletedTime,
      userId: userTraining.userId,
      startedAt: userTraining.startedAt,
      updated: userTraining.updated,
      chapters: userTraining.chapters,
      completed: userTraining.completed,
      calculateTrainingStatus: userTraining.calculateTrainingStatus,
      completedPercents: userTraining.completedPercents,
      exam: userTraining.exam,
      examMin: userTraining.examMin,
      examResults: userTraining.examResults,
      passed: userTraining.passed,
      reachedPoints: userTraining.reachedPoints,
      totalPoints: userTraining.totalPoints,
      score: userTraining.score,
      badgeUrl: userTraining.badgeUrl
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new UserTraining(snapshot.id, data.trainingId, data.trainingTitle, data.totalTime, data.totalCompletedTime, data.userId, data.startedAt.toDate(), data.updated.toDate(), data.chapters, data.completed, data.calculateTrainingStatus, data.completedPercents, data.exam, data.examMin, data.examResults, data.passed, data.reachedPoints, data.totalPoints, data.score, data.badgeUrl);
  }
};
