<mat-card class="card">
  <form [formGroup]="userInfoFormControl">
    <mat-card-header class="header">
      <div class="profile-img-container">
        <img [src]="photoURL ?? 'https://ui-avatars.com/api/?name=' + initialNameLetters" class="profile-img"
          alt="Avatar" />
        <!-- <button type="button" class="edit-button" (click)="fileInput.click()">
          <mat-icon class="edit-icon">edit</mat-icon>
        </button>
        <input #fileInput type="file" hidden (change)="uploadImage($event, userInfo)" /> -->
      </div>
    </mat-card-header>

    <mat-card-content class="content">
      <div class="grid">
        <div class="form-group">
          <label class="label" for="first-name">Vorname</label>
          <input class="input" type="text" name="first-name" id="first-name" [placeholder]="userInfo.firstName"
            autocomplete="first-name" formControlName="firstName" />
        </div>

        <div class="form-group">
          <label class="label" for="last-name">Nachname</label>
          <input class="input" type="text" name="last-name" id="last-name" [placeholder]="userInfo.lastName"
            autocomplete="family-name" formControlName="lastName" />
        </div>

        <div class="form-group">
          <label class="label" for="email-address">E-Mail</label>
          <input class="input" type="text" name="email-address" id="email-address" [placeholder]="userInfo.email"
            [disabled]="true" readonly autocomplete="email" formControlName="email" />
        </div>
      </div>
    </mat-card-content>
  </form>
</mat-card>
