import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-elements',
  templateUrl: './nav-elements.component.html',
  styleUrls: ['./nav-elements.component.scss']
})
export class NavElementsComponent {
  @Input() isAdmin: boolean = false;
  @Input() exact: boolean = true;

  @Input() link: string | undefined;
  @Input() name: string | undefined;
  @Input() icon: string | undefined;

}
