import { Injectable } from '@angular/core';
import {collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where} from "@angular/fire/firestore";
import {contentConverter} from "../models/content.model";

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  db = getFirestore();

  constructor() { }

  getContent(trainingId: string, chapterId: string, contentId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId);
    return getDoc(ref.withConverter(contentConverter));
  }

  getContents(trainingId: string, chapterId: string, contentOrderNo: number) {
    const contentRef = collection(this.db, "trainings", trainingId, "chapters", chapterId, "contents");
    const q = query(contentRef.withConverter(contentConverter),
      where("order", "==", contentOrderNo),
      orderBy("order"));
    return getDocs(q);
  }
}
