import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Chapter} from "../../models/chapter.model";
import {ChapterService} from "../../services/chapter.service";
import {Training} from "../../models/training.model";
import {TrainingChapter} from "../../models/training-chapter.model";
import {UserTrainingService} from "../../../../shared/services/user-training.service";

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent implements OnInit{

  @Input() training: Training | null = null;
  @Input() isAdmin: boolean = false;
  @Output() chapterChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadedChapter: boolean = false;
  trainingId: string = '';
  chapterId: string = '';
  contentOrderNo: number = 1;
  contentId: string = '';
  chapter: Chapter | null = null;
  hideButtonRow: boolean = true;
  disableNextButton: boolean = false;
  disablePreviousButton: boolean = false;
  nextChapter: TrainingChapter | null = null;
  previousChapter: TrainingChapter | null = null;
  timePercent = 0;
  completedContent = false;
  userVideo: any;
  contentType: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private chapterService: ChapterService,
    private userTrainingService: UserTrainingService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.trainingId = <string>params['id'];
      this.chapterId = <string>params['chapterId'];
      this.contentOrderNo = params['contentOrderNo'] ? parseInt(<string>params['contentOrderNo']) : 1;
      this.getChapter();
    });
  }

  getChapter() {
    this.loadedChapter = false;
    this.chapterService.getChapter(this.trainingId, this.chapterId).then(chapterSnap => {
      if (chapterSnap.exists()) {
        this.chapter = chapterSnap.data();
        console.log(this.chapter);
        this.nextChapter = this.training!.details!.chapters.find(chapter => chapter.order === this.chapter!.order + 1) || null;
        this.previousChapter = this.training!.details!.chapters.find(chapter => chapter.order === this.chapter!.order - 1) || null;
        if (this.contentOrderNo === -1) {
          this.contentOrderNo = this.chapter!.details!.contents.length;
        }
        if (this.contentOrderNo === 1) {
          const content = this.chapter.details.contents.find((co: any) => co.order === 1);
          this.userTrainingService.addChapterStart(this.trainingId, this.chapterId, content.id).then();
        }
        this.loadedChapter = true;
      }
    });
  }

  calculateRemainingTime(seconds: number) {
    this.timePercent = 0;
    const time = seconds * 1000;
    const interval = setInterval(() => {
      this.timePercent = this.timePercent + 1;
      if (this.timePercent >= 100) {
        clearInterval(interval);
      }
    }, time / 100);
  }

  previous() {
    this.disableNextButton = false;
    this.disablePreviousButton = false;
    this.completedContent = false;
    if (this.contentOrderNo === 1) {
      if (this.previousChapter) {
        this.router.navigate(['/trainings', this.trainingId, 'chapter', this.previousChapter.id,
          'content', -1]).then(() => {
          this.chapterChanged.emit(true)
        });
      }
    } else {
      this.router.navigate(['/trainings', this.trainingId, 'chapter', this.chapterId,
        'content', this.contentOrderNo - 1]).then();
    }
  }

  next() {
    this.disableNextButton = false;
    this.disablePreviousButton = false;
    this.completedContent = false;
    if (!this.completedContent) {
      this.userTrainingService.addCompletedContent(this.trainingId, this.chapterId, this.contentId).then(() => {
        this.completedContent = true;
      });
    }
    if (this.contentOrderNo === this.chapter?.details.contents.length) {
      // go to next chapter
      if (this.nextChapter) {
        this.router.navigate(['/trainings', this.trainingId, 'chapter', this.nextChapter.id, 'content', '1']).then(() => {
          this.chapterChanged.emit(true)
        });
      }
    } else {
      this.router.navigate(['/trainings', this.trainingId, 'chapter', this.chapterId,
        'content', this.contentOrderNo + 1]).then();
    }
  }

  onDisableNextButton(disable: boolean) {
    this.disableNextButton = disable;
  }

  onQuizCompleted(completed: boolean) {
    this.disableNextButton = !completed;
  }

  onTimeTrackingChanged(changed: any) {
    console.log(changed);
    if (changed.watchedTime) {
      this.userTrainingService.updateVideoStatus(this.trainingId, this.chapterId, this.contentId, changed).then(() => {
        this.completedContent = changed.completed;
      });
    }
  }

  onReceivedContent(content: any) {
    this.completedContent = false;
    const contentId = content.id;
    if (contentId) {
      this.contentType = content.type;
      this.contentId = contentId;
      this.userTrainingService.getUserTraining(this.trainingId).then(userTraining => {
        let seconds = this.chapter!.details!.contents.find((content: any) => content.id === this.contentId)!.time;
        if (userTraining) {
          const chapter = userTraining.chapters.find((chapter: any) => chapter.chapterId === this.chapterId);
          if (chapter) {
            const content = chapter.contents.find((content: any) => content.contentId === this.contentId);
            if (content) {
              if (content.video) {
                this.userVideo = content.video;
                if (content.video.completed) {
                  seconds = 0;
                  this.completedContent = true;
                }
              } else if (content.completed) {
                seconds = 0;
                this.completedContent = true;
              }
            }
          }
        }
        if (this.contentType != 'video') {
          this.calculateRemainingTime(seconds);
        }
      });
      this.hideButtonRow = false;
    }
  }
}
