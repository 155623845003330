import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ChapterComponent } from './pages/chapter/chapter.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatListModule} from "@angular/material/list";
import {RouterLink} from "@angular/router";
import {ContentModule} from "../content/content.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    ChapterComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    SharedModule,
    MatListModule,
    RouterLink,
    ContentModule,
    TranslateModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ], exports: [
    ChapterComponent,
  ]
})
export class ChapterModule { }
