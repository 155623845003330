<div class="chapter-container w-full max-w-screen-lg">
  <div class="title-section">
    <h1 class="text-3xl font-bold">{{'e-learning_training_overview' | translate}}</h1>
    <p class="text-gray-500">
      {{'e-learning_training_overview_intro' | translate}}
    </p>
  </div>
  <br />
  <app-training-list></app-training-list>

</div>
