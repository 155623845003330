<div>
  <app-personal-info-form class="personal-info" *ngIf="userInfo" [userInfo]="userInfo" [photoURL]="photoURL"
    (onFormGroupChange)="onFormGroupChangeEvent($event)"></app-personal-info-form>

  <!-- <div class="hidden sm:block" aria-hidden="true">
    <div class="py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div>

  <div class="mt-10 sm:mt-0 hidden">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Unternehmensinformationen
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            Hier können Sie Ihre Unternehmensinformationen einsehen.
          </p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <div class="overflow-hidden shadow sm:rounded-md">
          <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 lg:col-span-3">
                <label for="companyName" class="block text-sm font-medium text-gray-700">Gruppe</label>
                <input name="companyName" id="companyName" value="TestADm" class="bg-black text-center text-white"
                  disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden sm:block" aria-hidden="true">
    <div class="hidden py-5">
      <div class="border-t border-gray-200"></div>
    </div>
  </div> -->

  <div class="button-container" *ngIf="userInfo" >
    <button class="save-btn"mat-raised-button (click)="updateProfile(updatedData)">
      Profil speichern
    </button>
  </div>
</div>