<footer class="impressum-footer bg-gray-100 text-center py-4 mt-10 border-t border-gray-300">
    <div class="container mx-auto px-4">
      <p class="text-sm text-gray-600">
        © 2024 SBS Online Training. Alle Rechte vorbehalten.
      </p>
      <div class="mt-2">
        <a href="https://www.sbs-info.de/impressum" class="text-red-600 hover:text-red-800 px-2">Impressum</a> |
        <a href="https://www.sbs-info.de/datenschutzhinweise" class="text-red-600 hover:text-red-800 px-2">Datenschutz</a> |
        <a href="" class="text-red-600 hover:text-red-800 px-2">AGB</a> |
      </div>
    </div>