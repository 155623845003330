import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "../../core/services/auth/authentication.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  url = environment.functionsUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }

  downloadCertificate(trainingId: string) {
    return new Promise((resolve) => {
      this.authService.getToken().subscribe((token) => {
        const options: any = {
          headers: {
            Authorization: `Bearer ${token}`
          },
          responseType: 'blob'
        };
        return this.http.get<any>(`${this.url}/downloadCertificate?trainingId=${trainingId}`, options).subscribe((data) => {
          this.downloadFile(data);
          resolve(true);
        }, (error) => {
          console.error(error);
          resolve(false);
        });
      });
    });
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }
}
