<div class="w-full max-w-screen-lg">
  <div class="header flex items-center">
    <button mat-button [routerLink]="'/trainings/' + trainingId" class="back-button mr-auto">
      <mat-icon>chevron_left</mat-icon>
      {{ 'e-learning_back_to_chapter_overview' | translate }}
    </button>
    <div class="course-progress-wrapper flex-grow">
      <app-course-progress *ngIf="training?.details"
                           [chapters]="training?.details?.chapters!"
                           [trainingCompleted]="trainingCompleted">
      </app-course-progress>
    </div>
  </div>

  <app-chapter
    *ngIf="training"
    [training]="training"
    [isAdmin]="isAdmin"
    (chapterChanged)="onChapterChanged($event)">
  </app-chapter>
</div>
