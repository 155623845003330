<div class="verify-container">
    <div class="verify-box">
      <h1>Fast Geschafft!</h1>
      <div class="icon-container">
        <i class="material-icons mail-icon">mail_outline</i>
      </div>
      <p>
        Wir haben soeben einen Verifizierungslink an Ihre E-Mail geschickt. Bitte überprüfen Sie Ihr Postfach und klicken Sie auf diesen Link, um Ihre E-Mail-Adresse zu bestätigen.
      </p>
      <div class="button-group">
        <button (click)="checkEmailVerified()">Weiter</button>
      </div>
      <div class="link-group">
        <span class="resend-link" (click)="sendEmailVerification()">Email erneut senden</span>
        <span class="logout-link" (click)="logout()">Zurück zum Login</span>
      </div>
    </div>
  </div>
  