export class Chapter {
  id?: string;
  title: any;
  intro: any;
  description: any;
  targetGroups: string[];
  image?: any;
  order: number;
  details: any;
  exam: boolean;
  updated?: Date;
  created?: Date;

  constructor(id: string, title: any, intro: any, description: any, targetGroups: string[], image: any, order: number, details: any, exam: boolean, created?: Date, updated?: Date) {
    this.id = id;
    this.title = title;
    this.intro = intro;
    this.description = description;
    this.targetGroups = targetGroups;
    this.image = image;
    this.order = order;
    this.details = details;
    this.exam = exam;
    this.created = created;
    this.updated = updated;
  }
}

export const chapterConverter = {
  toFirestore: (chapter: Chapter) => {
    return {
      title: chapter.title,
      intro: chapter.intro,
      description: chapter.description,
      targetGroups: chapter.targetGroups,
      image: chapter.image || '',
      order: chapter.order || '',
      exam: chapter.exam || false,
      created: chapter.created || new Date(),
      updated: chapter.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Chapter(snapshot.id, data.title, data.intro, data.description, data.targetGroups, data.image, data.order, data.details, data.exam, data.created.toDate(), data.updated.toDate());
  }
};
