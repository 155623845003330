import { Component } from '@angular/core';
import {UserTrainingService} from "../../../../shared/services/user-training.service";

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent {

  constructor(
    private userTrainingService: UserTrainingService
  ) { }

  ngOnInit(): void {
    this.getUserTrainings()
  }

  getUserTrainings() {
    this.userTrainingService.getUserTrainings().then(userTrainings => {
      console.log(userTrainings);
    });
  }
}
