import { Injectable } from '@angular/core';
import { AngularFireStorage} from '@angular/fire/compat/storage';
import { from, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  constructor(
    private storage: AngularFireStorage,
  ) { }

  uploadImage(image: File, path: string): Observable<string> {
    return from(this.storage.upload(path, image).then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    }));
  }
}
