<div class="edit-profile-icon">
  <button
    mat-flat-button
    class="mr-4"
    attr.aria-label="{{ 'edit_profile' }}"
    [routerLink]="'/profile/edit'"
  >
    <mat-icon class="top-icon">{{ 'edit' }}</mat-icon>
  </button>
</div>
<div class="flex flex-col items-center md:flex-row tracking-wide">
  <img
    [src]="photoURL ?? 'https://ui-avatars.com/api/?name=' + initialNameLetters"
    class="rounded-full w-32 mx-auto md:w-24 md:mx-0"
    alt="Avatar"
  />

  <div class="ml-5">
    <h5 class="text-3xl font-medium">{{ fullName }}</h5>
    <!-- <p class="text-gray-500">{{ "personnel_number:" | translate }}</p> -->
     <p class="text-sm font-bold">{{ companyName }}</p>
  </div>

</div>
