import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto' as FloatLabelType);
  options = this._formBuilder.group({
    hideRequired: this.hideRequiredControl,
    floatLabel: this.floatLabelControl,
  });
  hide = true;
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required, Validators.minLength(6)]);



  accountErrorMessage: string | undefined;

  constructor(
    public auth_service: AuthenticationService,
    public auth: AngularFireAuth,
    private _formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService
  ) { }

  getErrorMessage(control: FormControl, field: string): string {
    if (control.hasError('required')) {
      return `${field} ist erforderlich`;
    }
    if (control.hasError('minlength')) {
      const minLength = control.getError('minlength').requiredLength;
      return `${field} muss mindestens ${minLength} Zeichen lang sein`;
    }
    if (control.hasError('email')) {
      return 'Ungültige E-Mail-Adresse';
    }
    return '';
  }

  isFormInvalid(): boolean {
    return this.emailFormControl.invalid || this.passwordFormControl.invalid;
  }

  login() {
    this.auth_service
      .signIn(this.emailFormControl.value!, this.passwordFormControl.value!)
      .then((res) => {
        this.auth.updateCurrentUser(res.user);
        // this.router.navigate(['/profile']);
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found': {
            this.accountErrorMessage = this.translate.instant('e-learning_training_login_error');
            break;
          }
          default: {
            this.accountErrorMessage = 'Unexpected Error';
            break;
          }
        }
      });
  }

}
