import { Component, Input } from '@angular/core';
import { User } from '../../models/user.model'

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent {
  @Input() photoURL!: string | null;
  @Input() fullName!: string;
  @Input() isTrainer!: boolean;
  @Input() personnelNumber!: string;
  @Input() companyName!: string;

  @Input() userInfo!: User | null;

  initialNameLetters: string = '';

  ngOnInit(): void {
    if(this.userInfo){
      this.fullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      this.isTrainer = this.userInfo.isTrainer;
      this.personnelNumber = this.userInfo.personnelNumber;
      this.companyName = this.userInfo.companyName;

      this.initialNameLetters = this.userInfo.firstName.charAt(0) + this.userInfo.lastName.charAt(0);
    }    
  }
}
