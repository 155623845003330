<div class="p-5">
  <div class="mx-4 p-4">
    <div class="flex items-center" *ngIf="preparedChapters.length > 0">
      <div *ngFor="let chapter of preparedChapters" class="flex items-center w-full">
        <app-step-circle
          class="flex items-center relative"
          [step]="chapter.order"
          [active]="chapter.active"
          [completed]="chapter.completed"
        ></app-step-circle>
        <app-step-connector class="w-full"
          [active]="chapter.nextChapter?.active"
          [completed]="chapter.nextChapter ? chapter.nextChapter.completed : trainingCompleted">
        </app-step-connector>
      </div>
      <app-step-circle
        class="flex items-center relative"
        [icon]="'crown'"
        [completed]="trainingCompleted"
      ></app-step-circle>
    </div>
  </div>
</div>
