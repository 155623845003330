import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { IFirestoreUser } from 'src/app/features/profile/interface/firestore-user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public showOutlet: boolean = false; //workaround for showing outlet 

  constructor(
    public fs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore
  ) { }

  signIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.afAuth.signOut();
  }

  async signUp(email: string, password: string, firstName: string, lastName: string) {
    try {
      const res = await this.afAuth.createUserWithEmailAndPassword(email, password);
      
      if (res.user) {
        try {
          await res.user.sendEmailVerification();
          console.log('Verification email sent.');
        } catch (error) {
          console.error('Error sending verification email:', error);
        }
  
        const userData: IFirestoreUser = {
          email: res.user.email!,
          deviceId: "", 
          group: "",  
          firstName,
          lastName,
          storeRegistration: false,
          uid: res.user.uid
        };
    
        await this.saveUserDataToFirestore(userData);      
        await this.afAuth.updateCurrentUser(res.user);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  }
  
  async saveUserDataToFirestore(userData: IFirestoreUser): Promise<void> {
    try {
      await this.afStore.collection('users').doc(userData.uid).set(userData);
      console.log('User data saved to Firestore');
    } catch (error) {
      console.error('Error saving user data:', error);
      throw new Error('Error saving user data to Firestore');
    }
  }

  getCurrentUser() {
    return this.afAuth.user;
  }

  getCurrentUserPromise(): Promise<firebase.User | null> {
    return new Promise((resolve) => {
      this.afAuth.user.subscribe(user => {
        resolve(user);
      });
    });
  }

  getUserData(uid: string) {
    return this.afStore.collection('users').doc(uid).valueChanges();
  }



  isAuthenticated() {
    return this.afAuth.user.pipe(map(user => user !== null));
  }

  isEmailVerified() {
    return this.afAuth.user.pipe(map(user => user !== null && user.emailVerified));
  }

  forgotPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  getToken() {
    return this.afAuth.idToken;
  }

  sendEmailVerification() {
    this.afAuth.currentUser.then(user => {
      if (user) {
        user.sendEmailVerification()
          .then(() => {
            alert('Eine Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihr Postfach.');
          })
          .catch((error) => {
            console.error('Error sending verification email:', error);
          });
      }
    });
  }

  checkEmailVerified(): Promise<boolean> {
    return this.afAuth.currentUser.then(user => {
      if (user) {

        return user.reload().then(() => {

          return user.emailVerified;
        });
      } else {
        return Promise.resolve(false);
      }
    }).catch(error => {
      console.error('Fehler beim Überprüfen der E-Mail-Verifizierung:', error);
      return Promise.resolve(false);
    });
  }
}
